import http from '@/utils/request.js'

// 获取通知列表
export const getNoticeList = (params) => {
  return http.post('/i/system/cmsNotice/webPage', params, { domainName: 'cpi' })
}

// 获取数量
export const getNoticeCount = (params) => {
  return http.post('/i/system/cmsNotice/getConut', params, { domainName: 'cpi' })
}

// 标识已读
export const getNoticeRand = (params) => {
  return http.post('/i/system/cmsNotice/rand', params, { domainName: 'cpi' })
}

// 删除
export const getNoticeDel = (params) => {
  return http.post('/i/system/cmsNotice/del', params, { domainName: 'cpi' })
}
