
import axios from 'axios'
import { Message } from '@arco-design/web-vue';
import config from '../config'
import router from '@/router';


const http = axios.create()
// req.domainName
http.interceptors.request.use((req) => {
  req.baseURL = process.env.NODE_ENV !== 'production'?'/' + req.domainName :'/apis'
  req.headers['ext-jd-token'] = window.localStorage.getItem('baseId') || '';
  req.headers.Token = window.localStorage.getItem('webtoken') || '';
  return req
}, err => {
  return Promise.reject(err)
})

http.interceptors.response.use((res) => {
  if (res.data.code && res.data.code != 20000 && res.data.code != 200) {
    Message.error(res.data.msg)
    if (res.data.code === 20015) {
      window.localStorage.clear()
      router.push({ path: '/login' })
    }
  }
  // console.log('响应拦截req', res)
  return res.data
}, err => {
  Message.error(err.message)
  return Promise.reject(err)
})

export default http
