import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入UI库
// import { ArcoVue, Message } from '@arco-design/web-vue'
import ArcoVue from '@arco-design/web-vue'
import ArcoVueIcon from '@arco-design/web-vue/es/icon'
import '@arco-design/web-vue/dist/arco.css'
// 引入全局css样式
import './assets/css/public.css'

const app = createApp(App)
app
  .use(store)
  .use(router)
  .use(ArcoVue)
  .use(ArcoVueIcon)
  .mount('#app')
