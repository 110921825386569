import { createStore } from 'vuex'
import { getNoticeCount } from "@/api/message.js"

export default createStore({
  state: {
    count: 0,
    user: '',
  },
  getters: {
    getuser(state) {
      return state.user;
    }
  },
  mutations: {
    saveCount(state, val) {
      state.count = val
    },
    saveUser(state, val) {
      state.user = val
    }
  },
  actions: {
    updateCount(context) {
      getNoticeCount().then(res => {
        if (res.code === 20000) {
          const count = res.result.count
          context.commit('saveCount', count)
        }
      })
    }
  },
  modules: {
  }
})
