<template>
  <div class="footer">
    <!-- <div class="wrapper">
      <div class="footer-floor-t">
        <div class="title">友情链接</div>
        <div class="container">
          <div>交通运输网络学院继续教育</div>
          <div>网络安全大讲堂</div>
          <div>交通运输部管理干部学院</div>
        </div>
      </div>
    </div> -->
    <div class="footer-floor-c">
      <div class="wrapper">
        <div class="container">
          <div class="logo">
            <img src="../../assets/images/logoa.png" style="height: 40px;">
          </div>
          <div class="right">
            <ul>
              <li>关于平台</li>
              <li>
                <span @click="to" class="cursor">联系我们</span>
                <span>关于我们</span>
              </li>
              <li>
                <span>帮助中心</span>
                <span>隐私政策</span>
              </li>
              <li>用户协议</li>
            </ul>
            <ul>
              <li>客服</li>
              <li>13369695110</li>
            </ul>
            <!-- <img style="width: 100px; height: 100px; margin-top: 20px; margin-left: 20px;" src="../../assets/images/aboutwe.png" alt=""> -->
          </div>
        </div>
      </div>
    </div>
    <div class="footer-floor-b">
      <p>Copyright © 2022 北京欧坤科技有限公司 版权所有</p>
      <!-- <p style="margin-top: 6px;">北京欧坤科技有限公司</p> -->
      <p style="margin-top: 6px;">
        <span class="cursor beian" @click="tobeian">京ICP备2022035039号</span>
        <img
          src="//p3.img.cctvpic.com/photoAlbum/page/performance/img/2020/11/13/1605265483789_608.png"
          style="height:18px;margin: -2px 5px 0 5px;"
        />
        <span class="cursor beian" @click="tobeian1">京公网安备 11010502051510号</span>
      </p>
    </div>
  </div>
</template>

<script setup>
  import { useRoute, useRouter } from 'vue-router'
  const router = useRouter()

  const tobeian = () => {
    window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
  }
  const tobeian1 = () => {
    window.open('http://www.beian.gov.cn/portal/registerSystemInfo?spm=C28340.PdNvWY0LYxCP.ERyba75kKojv.5&recordcode=11010502051510', '_blank')
  }
  const to = () => {
    // router.push({
    //   path: '/jtserve/detail3'
    // });
  }
</script>

<style lang="less" scoped>
.beian:hover {
  color: #ff4136;
}
.footer {
  background: #272C32;
  .footer-floor-t {
    padding: 20px 0;
    .title {
      text-align: center;
      font-size: 16px;
      color: #fff;
    }
    .container {
      padding: 0 270px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #B7B7B7;
    }
  }
  .footer-floor-c {
    padding: 22px 0;
    border-top: 1px solid #383E45;
    border-bottom: 1px solid #383E45;
    .container {
      display: flex;
      .logo {
        margin-top: 30px;
        width: 270px;
        font-size: 28px;
        color: #fff;
      }
      .right {
        flex: 1;
        // background: pink;
        display: flex;
        ul {
          margin-right: 130px;
        }
        li:first-child {
          font-size: 18px;
          color: #FFFFFF;
          margin-bottom: 20px;
        }
        li {
          margin-top: 16px;
          color: #B7B7B7;
          font-size: 14px;
          span + span {
            padding-left: 60px;
          }
        }
      }
    }
  }
  .footer-floor-b {
    padding: 22px 0;
    text-align: center;
    color: #B7B7B7;
    font-size: 14px;
  }
}
</style>
