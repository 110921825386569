<template>
  <div class="navbar">
    <div class="wrapper">
      <div class="navbar-container clearfix">
        <div class="pull-left cursor label" @click="handleTrigger(item, index)" :class="index == active? 'active': ''" v-for="(item, index) in list" :key="index">{{item.label}}</div>
        <!-- <div class="pull-right search">
          <a-input-search :style="{width:'220px'}" v-model="search" @search="searchVal" placeholder="搜索课程"/>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Message } from '@arco-design/web-vue'
export default {
  props: {
    list: { type: Array }
  },
  setup (props) {
    const router = useRouter()
    const state = reactive({
      active: '10000',
      search: ''
    })
    watch(() => router.currentRoute.value.path, (newPath, oldPath) => {
      const result = props.list.findIndex(item => {
        return item.path == newPath
      })
      if (result == -1) {
        state.active = '10000'
      } else {
        state.active = result
      }
    }, { immediate: true })
    const handleTrigger = (row, index) => {
      // if (row.label == '新闻公告') return router.push({ path: row.path, query: { cmsTypeId: 1 } })
      // if (row.label == '培训活动') return router.push({ path: row.path, query: { cmsTypeId: 2 } })
      // if (row.path == '/new/list') return router.push({ path: row.path, query: { cmsTypeId: 1 } })
      if (row.path) {
        router.push({ path: row.path })
      }
    }
    const searchVal = () => {
      if (!state.search) return Message.warning('搜索关键词不能为空')
      router.push({
        path: '/search',
        query: {
          text: state.search
        }
      })
    }
    return {
      ...toRefs(state),
      handleTrigger,
      searchVal
    }
  }
}
</script>

<style lang="less" scoped>
.navbar {
  margin-top: 1px;
  width: 100%;
  height: 50px;
  background: #F7F6FA;
}
.navbar-container {
  .active {
    // background: #096DD9;
    color: #4F17A8;
    position: relative;
  }
  .active::after {
    content: '';
    height: 4px;
    width: 60px;
    background-color: #4F17A8;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .label {
    height: 50px;
    line-height: 50px;
    // color: #212121;
    font-weight: 500;
    font-size: 16px;
    padding: 0 25px;
  }
  .search {
    line-height: 50px;
  }

}
</style>
