]<template>
  <div class="homePage-header">
    <div class="header-top">
      <div class="img-box">
        <img class="logo-img1" src="../../assets/images/dian.png" alt="">
        <img class="logo-img" src="../../assets/images/logo.png" alt="">
      </div>
      <div class="header-top-container">
        <!-- <span style="padding-right: 30px;">交通运输行业网络安全大讲堂欢迎您！</span> -->
        <div v-if="state.status == 3" style="display: flex;align-items: center;">
          <div style="margin-right: 20px;position: relative;">
            <router-link to="/account/notification">
              <icon-notification style="font-size: 16px;cursor: pointer;" />
            </router-link>
          </div>
          <a-dropdown trigger="hover" position="bottom" >
            <div style="color: #323233; font-size: 14px;cursor: pointer;">
              <img style="height: 25px;width: 25px;" src="../../assets/images/student.png" alt="">
              {{ $store.getters.getuser.nickName.split('#')[0] }}
            </div>
            <template #content>
              <a-doption>
                <div class="doption-row" @click="$router.push({ path: '/account/center' })">
                  <icon-user />
                  <span class="doption-row-text">个人中心</span>
                </div>
              </a-doption>
              <a-doption>
                <div class="doption-row" @click="$router.push({ path: '/account/course' })">
                  <icon-book />
                  <span class="doption-row-text">我的课程</span>
                </div>
              </a-doption>
              <a-doption>
                <div class="doption-row" @click="logout">
                  <icon-import />
                  <span class="doption-row-text">退出登录</span>
                </div>
              </a-doption>
            </template>
          </a-dropdown>
        </div>
        <div v-else>
          <span class="cursor" @click="sign">登录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { reactive } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { Message } from '@arco-design/web-vue'
  const router = useRouter()
  const route = useRoute();
  const store = useStore();
  const state = reactive({
    name: '',
    status: 3,
  });
  // 获取用户信息
  const getUserInfo = () => {
    if (window.localStorage.getItem('webtoken') && window.localStorage.getItem('webuserInfo')) {
      // --
      const userInfo = window.localStorage.getItem('webuserInfo');
      store.commit('saveUser', JSON.parse(userInfo));
    } else {
      // 跳登录页面
      state.status = 1;
    }
  }
  getUserInfo();
  const sign = () => {
    router.push({ path: '/login' });
  }
  // 退出
  const logout = () => {
    window.localStorage.clear()
    router.push({ path: '/login' })
  }
</script>

<style>
.drawer .arco-drawer-body {
  padding: 0;
}
</style>

<style lang="less" scoped>
@ggg: #ff4136;

.homePage-header {
  width: 100%;
  // height: 80px;
  box-shadow: 0px 1px 0px 0px #E1E1E1;
}
.header-top {
  width: 1200px;
  margin: auto;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .img-box {
    display: flex;
    align-items: center;
    position: relative;
    .logo-img1 {
      display: block;
      height: 26px;
      width: 26px;
      margin-right: 30px;
      position: relative;
    }
    .logo-img {
      height: 34px;
    }
  }
  .img-box::after {
      content: '';
      height: 30px;
      width: 1px;
      background-color: #E1E1E1;
      position: absolute;
      left: 40px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

  .header-top-container {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    color: #323233;
  }
  .header-top-container::before {
    content: '';
    height: 26px;
    width: 1px;
    background-color: #E1E1E1;
    position: absolute;
    left: -20px;
    top: 7px;
  }
}
.header-container {
  height: 130px;
  display: flex;
  align-items: center;
}
.header-left {
  height: 130px;
  flex: 1;
  display: flex;
  align-items: center;
  .logo {
    font-size: 28px;
  }
  .long {
  margin: 0 10px;
  width: 1px;
  height: 32px;
  background: #E5E6EA;
  border-radius: 1px;
}
}
.header-right {
  display: flex;
  align-items: center;
  .not {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    .long {
      margin: 0;
    }
    .cursor {
      margin-left: 20px;
    }
    .sign-in {
      color: #1D2129;
    }
    .sign-up {
      width: 72px;
      height: 28px;
      background: #FFFFFF;
      color: #207AFF;
      border-radius: 4px;
      border: 2px solid #207AFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
}

.drawer-container {
  padding-left: 65px;
}
.drawer-item:hover {
  background: #F0F0F0;
}
.drawer-item {
  margin-top: 10px;
  width: 260px;
  height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  span {
    margin-left: 6px;
    flex: 1;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.doption-row:hover {
  color: #207AFF;
}
.doption-row {
  padding: 0 20px;
  display: flex;
  align-items: center;
  .doption-row-text {
    margin-left: 6px;
  }
}

// 1

</style>
