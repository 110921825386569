import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'

const routes = [
  {
    path: '/login',
    name: 'Index',
    redirect: '/login',
    component: () => import('@/views/sign/Index.vue'),
    children: [
      { path: '/login', name: 'Login', component: () => import('@/views/sign/Login.vue') },
      { path: '/signup', name: 'Signup', component: () => import('@/views/sign/Sign-up.vue') },
      { path: '/password_reset', name: 'Password_reset', component: () => import('@/views/sign/Password-reset.vue') }
    ]
  },
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    children: [
      { path: '/', name: 'Page', component: () => import('@/views/Page.vue') },
      { path: '/search', name: 'Search', component: () => import('@/views/search/Search.vue') },
      // 新闻公告
      // {
      //   path: '/new',
      //   redirect: '',
      //   component: () => import('@/views/new/Index.vue'),
      //   children: [
      //     { path: '', name: 'New', component: () => import('@/views/new/New.vue') }
      //     { path: 'search', name: 'New/Search', component: () => import('@/views/new/Search.vue') }
      //   ]
      // },
      // { path: '/new/list', name: 'New/List', component: () => import('@/views/new/List.vue') },
      { path: '/new/list1', name: 'New/List1', component: () => import('@/views/new/List1.vue') },
      { path: '/new/list2', name: 'New/List2', component: () => import('@/views/new/List2.vue') },
      { path: '/new/detail', name: 'New/Detail', component: () => import('@/views/new/Detail.vue') },
      { path: '/course', name: 'Course', component: () => import('@/views/course/Course.vue') },
      { path: '/course/list', name: 'Course/List', component: () => import('@/views/course/List.vue') },
      { path: '/course/courseware_a', name: 'Course/CoursewareA', component: () => import('@/views/course/Courseware-a.vue') },
      { path: '/course/courseware_b', name: 'Course/CoursewareB', component: () => import('@/views/course/Courseware-b.vue') },
      { path: '/activity', name: 'Activity', component: () => import('@/views/activity/Activity.vue') },
      { path: '/examination', name: 'Examination', component: () => import('@/views/examination/Examination.vue') },
      // 大讲堂
      { path: '/bigclass', name: 'Bigclass', component: () => import('@/views/djt/Bigclass.vue') },
      { path: '/trainbase', name: 'Trainbase', component: () => import('@/views/djt/Trainbase.vue') },
      { path: '/solution', name: 'Solution', component: () => import('@/views/djt/Solution.vue') },
      { path: '/solution/detail', name: 'Solutiondetail', component: () => import('@/views/djt/SolutionDetail.vue') },
      { path: '/business', name: 'Business', component: () => import('@/views/djt/Business.vue') },
      { path: '/business/detail', name: 'Businessdetail', component: () => import('@/views/djt/BusinessDetail.vue') },
      { path: '/jtserve', name: 'jtserve', component: () => import('@/views/djt/Jtserve.vue') },
      { path: '/policy', name: 'Policy', component: () => import('@/views/djt/Policy.vue') },
      { path: '/authen', name: 'Authen', component: () => import('@/views/djt/Authen.vue') },
      { path: '/jtserve/detail1', name: 'jtservedetail1', component: () => import('@/views/djt/JtserveDetail1.vue') },
      { path: '/jtserve/detail2', name: 'jtservedetail2', component: () => import('@/views/djt/JtserveDetail2.vue') },
      { path: '/jtserve/detail3', name: 'jtservedetail3', component: () => import('@/views/djt/JtserveDetail3.vue') },
      { path: '/jtother/detail1', name: 'jtotherdetail1', component: () => import('@/views/djt/JtotherDetail1.vue') },
      { path: '/jtother/detail2', name: 'jtotherdetail2', component: () => import('@/views/djt/JtotherDetail2.vue') },
      { path: '/jtother/detail3', name: 'jtotherdetail3', component: () => import('@/views/djt/JtotherDetail3.vue') },
      { path: '/jtother/detail4', name: 'jtotherdetail4', component: () => import('@/views/djt/JtotherDetail4.vue') },
      // 个人中心页面
      {
        path: '/account',
        redirect: '/account/center',
        component: () => import('@/views/account/Index.vue'),
        children: [
          { path: 'center', meta: { isShowF: true },  name: 'Account/Center', component: () => import('@/views/account/Center.vue') },
          { path: 'setting', meta: { isShowF: true }, name: 'Account/Setting', component: () => import('@/views/account/Setting.vue') },
          { path: 'info', meta: { isShowF: true }, name: 'Account/Info', component: () => import('@/views/account/Info.vue') },
          { path: 'invoice', meta: { isShowF: true }, name: 'Account/Invoice', component: () => import('@/views/account/Invoice.vue') },
          { path: 'course', meta: { isShowF: true }, name: 'Account/Course', component: () => import('@/views/account/Course.vue') },
          { path: 'courseware', meta: { isShowF: true }, name: 'Account/Courseware', component: () => import('@/views/account/Courseware.vue') },
          { path: 'notification', meta: { isShowF: true }, name: 'Account/Notification', component: () => import('@/views/account/Notification.vue') },
          { path: 'footprint', meta: { isShowF: true }, name: 'Account/Footprint', component: () => import('@/views/account/Footprint.vue') },
          { path: 'collect', meta: { isShowF: true }, name: 'Account/Collect', component: () => import('@/views/account/Collect.vue') },
          { path: 'certificate', meta: { isShowF: true }, name: 'Account/Certificate', component: () => import('@/views/account/Certificate.vue') }
        ]
      }
    ]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('@/views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
})

export default router
