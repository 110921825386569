// 生产环境
export default {
  CLIENT: {
    no: 'lingke', // 接口需要的机构号
    id: 'lkb65617f842ad4c37895a733b8de43cbb', // 接口需要的clientId
    name: '交通运输网络学院 - 继续教育', // 页面title
    domain: 'roncoo.net', // cookie作用域
    tokenName: 'OSTK', // cookie保存的名称
    mainUrl: 'https://eduos.roncoo.net' // 网站域名
  }
}
