<template>
  <div class="homePage">
    <Header ref="headerRef" />
    <Navbar :list="list" :active="active" />
    <router-view />
    <Footer v-if="!isShowF" />
  </div>
</template>

<script>
// 数据响应  定义数据
// ref                 定义基本数据类型
// reactive            定义一个复杂数据类型
// toRefs              将reactive()创建的对象 解构赋值
import { reactive, toRefs, watch } from 'vue'
import { useRouter } from 'vue-router'

import Header from '@/components/header/header.vue'
import Footer from '@/components/footer/footer.vue'
import Navbar from '@/components/navbar/navbar.vue'

export default {
  components: { Header, Footer, Navbar },
  name: 'Home',
  setup() {
    const router = useRouter()
    const state = reactive({
      isShowF: false,
      active: 0,
      list: [
        { label: '首页', path: '/' },
        { label: '课程库', path: '/course' },
        { label: '政策解读', path: '/policy' },
        { label: '认证培训', path: '/authen' },
        // { label: '大讲堂', path: '/bigclass' },
        // { label: '实训基地', path: '/trainbase' },
        // { label: '技术园地', path: '/solution' },
        // { label: '企业风采', path: '/business' },
        { label: '新闻公告', path: '/new/list1' },
        { label: '培训活动', path: '/new/list2' },
        // { label: '培训服务', path: '/jtserve' },
      ],
      foo: 1
    })
    watch(() => router.currentRoute.value, (newVal) => {
      state.isShowF = newVal.meta.isShowF || false
    }, { immediate: true })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
  .homePage {
    height: 100vh;
  }
</style>
